<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 col-xs-12 center">
            <img src="../../assets/img/sorteodoradobody.png" class="img-fluid" alt="Responsive image">
        </div>
    </div>
    <br>
    <div class="row">
        <div class="bd-example col-md-12 col-xs-12 center">
            <div id="accordion">
                <div class="card">
                    <div class="card-header" id="headingOne">
                        <h5 class="mb-0" style="text-align:center;">
                            <a class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> T&eacute;rminos y Condiciones
                            </a>
                        </h5>
                    </div>

                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion" style="">
                        <div class="card-body">
<!-- inicio de terminos y condiciones -->

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style="font-size:19px;">SORTEO ENTRADAS DOBLES TARDE CELESTE SPORTING CRISTAL</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style="font-size:19px;">&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><strong><span style="font-size:19px;">Validez:&nbsp;</span></strong><span style="font-size:19px;">04/01/2024 al 11/01/2024</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><span style="font-size:19px;">Realiza tus apuestas de S/20 o m&aacute;s en deportivas o deportes virtuales y participa por una de las 25 entradas dobles a la Tarde Celeste de Sporting Cristal. V&aacute;lido para apuestas en la web, en puntos f&iacute;sicos y v&iacute;a canal televentas.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><strong><span style="font-size:19px;">&iquest;C&oacute;mo participar?</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><span style="font-size:19px;">Puedes colocar tu apuesta a trav&eacute;s de la web Doradobet, en cualquiera de nuestros puntos f&iacute;sicos o a trav&eacute;s del canal de televentas.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><span style="font-size:19px;">Para las apuestas realizadas en la web, canales f&iacute;sicos y canal televentas, ser&aacute;n v&aacute;lidos todos los tickets de S/20 o m&aacute;s apostados en Deportivas as&iacute; como todos los tickets acumulados por un valor m&iacute;nimo total de S/20 en deportes virtuales.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><strong><span style="font-size:19px;">Si apuestas desde la web:</span></strong></p>
<ol style="list-style-type: decimal;">
    <li><span style="font-size:14.0pt;line-height:107%;">Deber&aacute;s primero registrarte en el sorteo d&aacute;ndole clic al men&uacute; lateral de Doradobet.com, luego a la opci&oacute;n &ldquo;sorteos&rdquo;, ubicas la imagen de la promoci&oacute;n y por &uacute;ltimo eliges la opci&oacute;n &ldquo;Participa aqu&iacute;&rdquo;.&nbsp;</span></li>
    <li><span style="font-size:14.0pt;line-height:107%;">Coloca tus apuestas de S/20 o m&aacute;s en deportivas o deportes virtuales.</span></li>
    <li><span style="font-size:14.0pt;line-height:107%;">En el caso de apuestas deportivas, cada apuesta de S/20 o m&aacute;s es una opci&oacute;n en el sorteo. En el caso de deportes virtuales, deber&aacute;s acumular un m&iacute;nimo de S/20 como sumatoria de los tickets apostados.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><strong><span style="font-size:19px;">Si apuestas en un punto f&iacute;sico o televentas:</span></strong></p>
<ol style="list-style-type: decimal;">
    <li><span style="font-size:14.0pt;line-height:107%;">Deber&aacute;s colocar una apuesta de S/20 o m&aacute;s por ticket en deportivas y para el caso de deportes virtuales, deber&aacute;s generar un m&iacute;nimo acumulado de S/20 como sumatoria de los tickets apostados.</span></li>
    <li><span style="font-size:14.0pt;line-height:107%;">Ingresa al link&nbsp;</span><strong><span style="line-height:107%;font-size:19px;">https://doradobet-sc.interplayword.com/</span></strong></li>
    <li><span style="font-size:14.0pt;line-height:107%;">Coloca tus datos personales y n&uacute;mero de ticket en el link anterior. Para el caso de tus jugadas de deportes virtuales, deber&aacute;s registrar todos los tickets que logren sumar el m&iacute;nimo de S/20 requerido.</span></li>
    <li><span style="font-size:14.0pt;line-height:107%;">Cada apuesta de S/20 o m&aacute;s es una opci&oacute;n en el sorteo.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><strong><span style="font-size:19px;">&iquest;C&oacute;mo se elige a los ganadores?</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style="font-size:14.0pt;">Para apuestas online, se tomar&aacute;n en cuenta todos los tickets registrados en la web, y para apuestas en puntos f&iacute;sicos o televentas, se tomar&aacute;n en cuenta las inscripciones que se hayan realizado en el link <strong>https://doradobet-sc.interplayword.com/</strong> con apuestas de S/20 o m&aacute;s en deportivas y deportes virtuales sin importar si han sido ganados o perdidos hasta el jueves 11 de enero a las 3:59 pm. No se tomar&aacute;n en cuenta los tickets con cashout.</span></li>
    <li><span style="font-size:14.0pt;">Se generar&aacute; un sorteo aleatorio para elegir a 20 ganadores registrados desde la web y 5 ganadores registrados en puntos f&iacute;sicos o a trav&eacute;s del canal de televentas.</span></li>
    <li><span style="font-size:14.0pt;">Se validar&aacute; que los datos est&eacute;n completos y sean reales.</span></li>
    <li><span style="font-size:14.0pt;">Se revisar&aacute; el ticket inscrito para validar que el(los) monto(s) de apuesta sea(n) de m&iacute;nimo S/20.</span></li>
    <li><span style="font-size:14.0pt;">Nos pondremos en contacto con los ganadores para hacerles llegar las entradas digitales v&iacute;a correo electr&oacute;nico y whatsapp.&nbsp;</span></li>
    <li><span style="font-size:14.0pt;">Las entradas llevan los nombres del ganador y su acompa&ntilde;ante. Es responsabilidad del ganador brindar los datos reales y completos, propios y de su acompa&ntilde;ante para poder hacer uso de las entradas.</span></li>
    <li><span style="font-size:14.0pt;">Las entradas son intransferibles.&nbsp;</span></li>
    <li><span style="font-size:14.0pt;">El sorteo se realizar&aacute; el jueves 11 de enero a las 4:00pm y Doradobet se comunicar&aacute; con los ganadores para validar los datos inscritos.</span></li>
</ul>

<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><strong><span style="font-size:19px;">Condiciones generales&nbsp;</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style="font-size:14.0pt;">V&aacute;lido para clientes mayores de 18 a&ntilde;os.</span></li>
    <li><span style="font-size:14.0pt;">Se descartar&aacute; a los ganadores que no hayan ingresado datos reales y correctos, que &nbsp; se encuentren incompletos o que no hayan cumplido con la mec&aacute;nica de la campa&ntilde;a.&nbsp;</span></li>
    <li><span style="font-size:14.0pt;">Un mismo cliente no podr&aacute; ser elegido m&aacute;s de una vez como ganador de las entradas.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><strong><span style="font-size:19px;">Premio</span></strong></p>
<ol style="list-style-type: circle;">
    <li><strong><span style="font-size:14.0pt;">25 entradas dobles a la Tarde Celeste de Sporting Cristal:</span></strong>
        <ol style="list-style-type: circle;">
            <li><span style="font-size:14.0pt;">20 entradas dobles para clientes inscritos a trav&eacute;s de la web.</span></li>
            <li><span style="font-size:14.0pt;">5 entradas dobles para clientes inscritos a trav&eacute;s del landing (con jugadas en puntos f&iacute;sicos o televentas).<strong><br>&nbsp;<br>&nbsp;</strong></span></li>
        </ol>
    </li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><strong><span style="font-size:19px;">Aplican T&amp;C generales de Doradobet.</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:"Calibri",sans-serif;'><strong><span style="font-size: 19px; font-family: Arial, Helvetica, sans-serif;">&nbsp;</span></strong></p>


<!-- fin de tyc -->








                            <div id="link">
                                <a target="_blank" href="https://doradobet.com/terminosycondiciones/promociones-bonos">Ver en doradobet.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
