import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, Validators, FormGroup} from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import { Output, EventEmitter } from '@angular/core';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-buscadni',
  templateUrl: './buscadni.component.html',
  styleUrls: ['./buscadni.component.css']
})
export class BuscadniComponent implements OnInit {

  @Output() nuevoEstado = new EventEmitter<any>();
  error: any;
  // cliente = {
  //   dni:null,
  //   nombres:'',
  // }
  formDni: FormGroup;
  validateCaptcha = false;
  isLoading = false;
  response = '';
  constructor( private fb:FormBuilder, private appService:AppService ) {
    // this.crearFormDni();
    this.formDni = this.fb.group({
      dni:['',[Validators.required, Validators.minLength(5)]]
    });
  }

  ngOnInit(): void {
//    Swal.fire({
//      imageUrl: '../../assets/img/banner3.png',
//      imageHeight: 237,
//      imageAlt: 'A tall image',
//     confirmButtonText: `Participar`,
//   })

  }

  crearFormDni(){

  }

  cambiarEstado(value: any) {
      this.nuevoEstado.emit(value);
  }

  buscarCliente(){
    console.log("Busqueda");
    console.log(this.formDni);
    if(this.formDni.invalid){
        console.log("Error");
    }else{
      console.log(this.formDni.value);

      if(this.validateCaptcha){
        this.appService.buscarDni(this.formDni.value)
        .subscribe((resp) => {
          if(resp.codigo){

            let c = {
              estado: 'tikets',
              cliente: resp.data.id,
              nombreCliente: resp.data.nombres

            }
            this.cambiarEstado(c);
            Swal.showLoading();
          }else{
            Swal.fire({
              position: 'center',
              title: '!Registrate y participa al toque!',
              showConfirmButton: true,
              confirmButtonText: `Ok`

            });

            // // Swal.showLoading();
            let c = {
              estado: 'registro',
              cliente: this.formDni.value.dni,
              nombreCliente: ""

            }
            console.log(c);
            this.cambiarEstado(c);
          }
        },(error)=>{
          this.error = error;
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Opps, no se pudo realizar el registro, intentelo más tarde',
            showConfirmButton: false,
            timer: 1500
          });
        })

      }else{
        this.isLoading = false;
        this.response = 'No soy un robot'; // 'Valide el recaptcha por favor'
      }


    }

  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse) {
      this.validateCaptcha = true;
    }
  }



  get dniNoValido(){
    return this.formDni.get('dni')!.invalid && this.formDni.get('dni')!.touched;
  }

  get disabledButon(){
    return this.formDni.get('dni')!.invalid;
  }
}
