

export class ClienteModel{

  public documento:string = '';
  public tipoDoc:string = '';
  public nombres:string = '';
  public apellidos:string = '';
  public telefono:string = '';
  public email:string = '';

  public constructor(init?: Partial<ClienteModel>) {
        Object.assign(this, init);
    }

}
