<div class="container-fluid">
  <div class="row">
    <h4>¡Registrate!</h4>
    <div id="contenedor" class="col-lg-12 col-xs-12 bg-light border border-white">
      <form autocomplete="off" [formGroup]="formCrearCli" (ngSubmit)="crearCliente()">
        <div class="form-group">
          <label for="exampleInputEmail1">DNI</label>
          <input type="text" class="form-control"
                  name="documento"
                  autocomplete="off" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                  formControlName="documento"
                  [class.is-valid] = "nombreNoValido">
          <small *ngIf="dniNoValido" id="emailHelp" class="form-text text-danger">Complete su dni. Deben ser minimo 5 digitos.</small>
        </div>

        <div class="form-group">
          <label for="exampleInputEmail1">Tipo de documento:</label>
          <select  class="form-control"
                    name="tipoDoc"
                    formControlName="tipoDoc"
                    [class.is-invalid] = "tipoDocNoValido">
            <option value="DNI">DNI</option>
            <option value="CE">Carnet de extranjería</option>
            <option value="PAS">Pasaporte</option>
            <option value="PTP">PTP</option>
          </select>
          <small *ngIf="tipoDocNoValido" id="emailHelp" class="form-text text-danger">Seleccione tipo de documento</small>
        </div>

        <div class="form-group">
          <label for="exampleInputEmail1">Nombres</label>
          <input type="text" class="form-control"
                  name="nombres"
                  formControlName="nombres"
                  [class.is-invalid] = "nombreNoValido">
          <small *ngIf="nombreNoValido" id="emailHelp" class="form-text text-danger">Digite su Nombre</small>
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Apellidos</label>
          <input type="text" class="form-control"
                  name="apellidos"
                  formControlName="apellidos"
                  [class.is-invalid] = "apellidoNoValido">
          <small *ngIf="apellidoNoValido" id="emailHelp" class="form-text text-danger">Digite su Apellido</small>
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Telefono</label>
          <input type="text" class="form-control"
                  name="telefono"
                  formControlName="telefono"
                  autocomplete="off" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                  [class.is-invalid] = "telefonoNoValido">
          <small *ngIf="telefonoNoValido" id="emailHelp" class="form-text text-danger">Digite su Telefono</small>
        </div>


   <div class="form-group">
          <label for="exampleInputEmail1">Email (OPCIONAL)</label>
          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  name="email"
                  formControlName="email"
                  [class.is-invalid] = "emailNoValido">
          <small *ngIf="emailNoValido" id="emailHelp" class="form-text text-danger">Digite su correo electronico</small>
        </div>   
        
        

<!-- 
        <div class="form-group">
          <label for="exampleInputEmail1">Email (opcional)</label>
          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                 name="email"
                 formControlName="email"
                 [class.is-invalid]="emailNoValido && formCrearCli.get('email')!.value">
          <small *ngIf="emailNoValido && formCrearCli.get('email')!.value" id="emailHelp" class="form-text text-danger">
            Por favor ingrese un correo electrónico válido.
          </small>
        </div> -->
        



        <div class="form-check">
          <input name="email" formControlName="checkDatos" type="checkbox" class="form-check-input" id="exampleCheck1">
          <label class="form-check-label" for="exampleCheck1">Acepto el uso y tratamiento de datos personales</label>
          <small *ngIf="checkNoDatos" id="emailHelp" class="form-text text-danger">Para participar debe aceptar.</small>
        </div>
        <br>
        <div class="text-center">
          <button type="submit" [disabled]="formNoValid" class="btn btn-block btn-info">Registrarme</button>
        </div>
      </form>
  </div>
</div>
