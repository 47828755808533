import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { ClienteModel } from '../models/clinte.models';


@Injectable({
  providedIn: 'root'
})
export class AppService {

  cliente = new ClienteModel();

  //private URL = 'http://127.0.0.1:8000/api';
  private URL = 'https://backoffice.interplayword.com/api';


  constructor(private http: HttpClient) { }



  crearCliente(cliente:ClienteModel):Observable<any>{
    return this.http.post<any>(`${this.URL}/set/cliente`,cliente)
                    .pipe(catchError(this.handleError));
  }

  crearParticipaciones(particip: { cliente: number; participacion: any; }):Observable<any>{
    return this.http.post<any>(`${this.URL}/set/participaciones`,particip)
                    .pipe(catchError(this.handleError));
  }

  buscarDni(dni:any):Observable<any>{
    return this.http.post<any>(`${this.URL}/get/cliente`,dni)
                    .pipe(catchError(this.handleError));
  }

  traerProductos():Observable<any>{
    return this.http.get<any>(`${this.URL}/get/productos`)
                    .pipe(catchError(this.handleError));
  }


  handleError(error: { message: any; }){
    console.log("Entro a consumir al error");
    return throwError(error.message || "Error del servidor");
  }

}
