import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClienteModel } from 'src/app/models/clinte.models';
import { Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

import { Observable } from 'rxjs';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-form-registro-cliente',
  templateUrl: './form-registro-cliente.component.html',
  styleUrls: ['./form-registro-cliente.component.css']
})
export class FormRegistroClienteComponent implements OnInit {

  @Input() idCliente:number = 0;

  @Output() nuevoEstado = new EventEmitter<any>();
    cambiarEstado(value: any) {
      this.nuevoEstado.emit(value);
  }


  private clienteModel:ClienteModel = new ClienteModel();

  formCrearCli!: FormGroup;


  error:any;


  constructor(private fb:FormBuilder, private AppService:AppService) {
    this.crearFormulario();
  }

  ngOnInit(): void {
    console.log(this.idCliente);
    this.formCrearCli.patchValue({ documento: this.idCliente });

  }


  crearFormulario(){

    this.formCrearCli = this.fb.group({
      documento:[null, [Validators.required, Validators.minLength(5)]],
      tipoDoc: [null, [Validators.required]],
      checkDatos: [null, [Validators.requiredTrue]],
      nombres:[null, [Validators.required, Validators.minLength(3)]],
      apellidos:[null, [Validators.required, Validators.minLength(3)]],
      telefono:[null,[Validators.required, Validators.minLength(5)]],
/*       email:[null,[Validators.required]] */
      /* email:[null,[Validators.email]] */
      email: [null, [Validators.email]]

    });

  }

 /*  crearCliente(){

    Swal.fire({
      icon: 'info',
      title: '¿Tus datos estan correctos?',
      text: 'Es clave para reclamar tu premio si eres un feliz ganador.',
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.clienteModel = new ClienteModel(this.formCrearCli.value);
        this.AppService.crearCliente(this.clienteModel)
        .subscribe((resp) => {
          console.log("Entro a al susces");
          console.log(resp);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Ha sido registrado correctamente',
            showConfirmButton: false,
            timer: 1500
          });

          let c = {
            estado: 'tikets',
            cliente: resp.id,
            nombreCliente: resp.nombres

          }
          this.cambiarEstado(c);
          Swal.showLoading();

        },(error)=>{
          console.log("Entro al error");
          console.log(error);
          this.error = error;
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Opps, no se pudo realizar el registro, intentelo más tarde',
            showConfirmButton: false,
            timer: 1500
          });

          let c = {
            estado: 'buscar',
            cliente: '',
            nombreCliente: ''
          }
          this.cambiarEstado(c);
          Swal.showLoading();
        })


      }
    })
  }
   */


  crearCliente() {
    Swal.fire({
      icon: 'info',
      title: '¿Tus datos están correctos?',
      text: 'Sobrin@ asegura haber colocado tus datos correctamente para poder participar del sorteo',
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
    }).then((result) => {
      if (result.isConfirmed) {
        // Asigna un correo electrónico predeterminado si el campo está vacío
        if (!this.formCrearCli.get('email')!.value) {
          this.formCrearCli.get('email')!.setValue('sincorreo@gmail.com');
        }
        // Continúa con la creación del cliente
        this.clienteModel = new ClienteModel(this.formCrearCli.value);
        this.AppService.crearCliente(this.clienteModel)
        .subscribe((resp) => {
          console.log("Entro a al susces");
          console.log(resp);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Ingresa nuevamente para registrar tus tickets',
            showConfirmButton: false,
            timer: 5000
          })
        .then(() => {
          // Aquí es donde rediriges a pepito.com
          window.location.href = 'https://www.doradobet-sc.interplayword.com';
        });

          this.cambiarEstado({
            estado: 'tickets',
            cliente: resp.id,
            nombreCliente: resp.nombres
          });
          Swal.showLoading();
        }, (error) => {
          console.log("Entro al error");
          console.log(error);
          this.error = error;
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Opps, no se pudo realizar el registro, intentelo más tarde',
            showConfirmButton: false,
            timer: 1500
          });

          this.cambiarEstado({
            estado: 'buscar',
            cliente: '',
            nombreCliente: ''
          });
          Swal.showLoading();
        });
      }
    })
  }









  get dniNoValido(){
    return this.formCrearCli.get('documento')!.invalid && this.formCrearCli.get('documento')!.touched;
  }
  get nombreNoValido(){
    return this.formCrearCli.get('nombres')!.invalid && this.formCrearCli.get('nombres')!.touched;
  }
  get apellidoNoValido(){
    return this.formCrearCli.get('apellidos')!.invalid && this.formCrearCli.get('apellidos')!.touched;
  }
  get telefonoNoValido(){
    return this.formCrearCli.get('telefono')!.invalid && this.formCrearCli.get('telefono')!.touched;
  }
  get tipoDocNoValido(){
    return this.formCrearCli.get('tipoDoc')!.invalid && this.formCrearCli.get('tipoDoc')!.touched;
  }
  get emailNoValido(){
    return this.formCrearCli.get('email')!.invalid && this.formCrearCli.get('email')!.touched;
  }
  get checkNoDatos(){
    return this.formCrearCli.get('checkDatos')!.invalid && this.formCrearCli.get('checkDatos')!.touched;
  }
  get formNoValid(){
    return this.formCrearCli!.invalid;
  }


}
