import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-registo-tikets',
  templateUrl: './form-registo-tikets.component.html',
  styleUrls: ['./form-registo-tikets.component.css']
})


export class FormRegistoTiketsComponent implements OnInit {

  @Input() nombreCliente:string = '';
  @Input() idCliente:number = 0;

  @Output() nuevoEstado = new EventEmitter<any>();
    cambiarEstado(value: any) {
      this.nuevoEstado.emit(value);
  }

  sorteo = 7;

  error:any;
  public formRegTikets: FormGroup;
  productos:any = [];



  constructor(private fb:FormBuilder, private appService:AppService) {

    this.formRegTikets = this.fb.group({
      tikets: this.fb.array([
        this.createItem()
      ])
    });
  }

  ngOnInit(): void {
    // this.crearFormularioTikets();
    // this.anadirTiket();
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: `Hola ${this.nombreCliente}`,
      text: 'A continuación debes registrar tus tickets. Cada ticket es una oportunidad de ganar!',
      confirmButtonText: `Ok`,

    });

    this.cargarSelectProductos();
  }

  getControls() {
    return (this.formRegTikets.get('tikets') as FormArray).controls;
  }

  cargarSelectProductos(){
    this.appService.traerProductos()
            .subscribe((resp)=> {
              console.log("Hola");
              console.log(resp);
              this.productos = resp;
            },(error)=>{
              console.log("Entro al error");
            });
  }


  get tikets(): FormArray {
    return this.formRegTikets.get('tikets') as FormArray;
  }

  registrarTikets(){

    let auxiliar = {
      sorteo: this.sorteo,
      cliente: this.idCliente,
      participacion: this.formRegTikets.value.tikets
    }

    //console.log(auxiliar);

    Swal.fire({
      icon: 'info',
      title: '¿Tus datos estan correctos?',
      text: 'Sobrin@ asegura haber colocado los datos de tus tickets correctamente para poder participar del sorteo',
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.appService.crearParticipaciones(auxiliar)
          .subscribe((resp) => {
            Swal.fire({
              icon: 'info',
              title: `Su solicitud ha sido procesada: `,
              html: this.convertirDatosHtml(resp),
              confirmButtonText: `Ok`,
            }).then((result) => {

              Swal.fire({
                position: 'center',
                icon: 'success',
                title: '!Mucha suerte!',
                text: 'Y no olvides seguir participando.',
                showConfirmButton: false,
                timer: 3500
              })
              let c = {
                estado: 'buscar',
                cliente: 0,
                nombreCliente: ''
              };

              this.cambiarEstado(c);

            });


          },(error)=>{
            this.error = error;
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Opps, no se pudo realizar el registro, intentelo más tarde',
              showConfirmButton: false,
              timer: 1500
            });
          })
       }
    })
  }


  convertirDatosHtml(data:any){
    let tablaSub =
    `<table class="table">
      <thead>
        <tr>
          <th scope="col">Código Ticket</th>
          <th scope="col">Tipo de juego:</th>
        </tr>
      </thead>
      <tbody>`;

      let tablaFot =
          `</tbody>
    </table>`;


    let tdValidos = '';
    let tdNoValidos = '';
    for(let tk in data.tiketsValidos){
      console.log('Entro al validos');
      console.log(tk);
      tdValidos += `<tr><td>${data.tiketsValidos[tk].codTiket}</td><td>${data.tiketsValidos[tk].juego}</td></tr>`;
    }
    for(let td in data.tiketNoValidos){
      console.log('Entro al no validos');
      console.log(td);
      tdNoValidos += `<tr><td>${data.tiketNoValidos[td].codTiket}</td><td>${data.tiketNoValidos[td].juego}</td></tr>`;    }

    if(tdNoValidos!=''){
      tdNoValidos = `<h4>Tickets <b>NO</b> aceptados*:</h4>
      ${tablaSub}
      ${tdNoValidos}
      ${tablaFot}
      <p>*No se han aceptado porque estan incompletos o ya fueron registrados.</p>`;
    }
    if(tdValidos!=''){
      tdValidos = `<h4>Tickets registrados:</h4>
      ${tablaSub}
      ${tdValidos}
      ${tablaFot}`;

    }

    let tabla = `${tdValidos} <br> ${tdNoValidos}`;

    return tabla;
  }

  createItem(): FormGroup {
    return this.fb.group({
      tpJuego: ['', [Validators.required]],
      codTiket: ['', [Validators.required, Validators.minLength(3)]],
      montoTiket: ['', [Validators.required]]
    });
  }

  crearFormularioTikets(){
    this.formRegTikets = this.fb.group({
      tikets: this.fb.array([
        this.createItem()
      ])
    });
    this.borrarTiket(0);
  }

  anadirTiket(){
    this.tikets.push(this.createItem());
  }

  borrarTiket(index:number){
    this.tikets.removeAt(index);
  }



  // get tpNoJuego(){
  //   return this.formRegTikets.get('tpJuego')!.invalid && this.formRegTikets.get('tpJuego')!.touched;
  // }
  // get formNoValid(){
  //   return this.formRegTikets!.invalid;
  // }


}
