<app-baner></app-baner>
<br>
<app-buscadni (nuevoEstado)="cambiarEstado($event)" *ngIf="estado==='buscar'"></app-buscadni>

<app-form-registro-cliente (nuevoEstado)="cambiarEstado($event)" [idCliente]="id" *ngIf="estado==='registro'"></app-form-registro-cliente>

<app-form-registo-tikets (nuevoEstado)="cambiarEstado($event)" [nombreCliente]="nombre" [idCliente]="id" *ngIf="estado==='tikets'"></app-form-registo-tikets>
<br>
<app-terminos></app-terminos>
<br>
<app-footer></app-footer>