<div class="container-fluid">
    <div class="row">
        <div id="contenedor" class="col-md-12 col-xs-12 bg-light border border-white">
            <h4>Estimad@ {{nombreCliente}}</h4>
            <p>Seleccione tipo, digite el codigo e indique el rango del valor de cada ticket.</p>
            <p style="font-style: italic">Recuerde que cada registro de ticket es una oportunidad de ganar.</p>
            <form autocomplete="off" [formGroup]="formRegTikets" (ngSubmit)=registrarTikets()>
                <div class="row" formArrayName="tikets" style="padding: 0 auto; marging: 0 auto;">
                    <fieldset>
                        <div class="row" *ngFor="let tik of getControls(); let i = index" style="margin: 0 auto; width: 100%;">
                            <legend style="float:center">Registre su Ticket</legend>
                            <div class="col-md-12">
                                <div class="row" [formGroupName]="i" style="margin-top:10px;">
                                    <div class="col-md-4">
                                        <select name="tpJuego" formControlName="tpJuego" class="form-control" placeholder="Juego - Producto">
                                          <option value="" disabled >Tipo de Ticket</option>
                                          <option value=1 selected="selected">Apuesta Doradobet</option>
                                          <!-- <option value=7 selected>Recarga Doradobet</option>-->
                                        </select>
                                        <!-- <small id="emailHelp" class="form-text text-danger">Seleccione juego</small> -->
                                    </div>
                                    <div class="col-md-4">
                                        <input formControlName="codTiket" type="text" class="form-control" placeholder="Código del ticket">
                                    </div>
                                    <div class="col-md-4">
                                        <select name="montoTiket" formControlName="montoTiket" class="form-control">
                                          <option value="" disabled selected>Monto</option>
                                          <option value="Entre S/.20 y S/.49">Entre S/.20 y S/.49</option>
                                          <option value="Entre S/.50 y S/.99">Entre S/.50 y S/.99</option>
                                          <option value="Entre S/.100 y S/.149">Entre S/.100 y S/.149</option>
                                          <option value="Entre S/.150 y S/.249">Entre S/.150 y S/.249</option>
                                          <option value="Mayor a S/.250">Mayor a S/.250</option>
                                        </select>
                                    </div>
                                    <!-- <div class="col-md-1" >
                    <button type="button" class="btn btn-block btn-outline-warning" (click)="borrarTiket(i)">-</button>
                  </div> -->
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="text-center" style="margin-top: 10px;">
                    <button type="button" class="btn btn-outline-info btn-sx" (click)="anadirTiket()">Agregar otro</button>
                </div>
                <div class="row" style="margin-top: 10px;">
                    <div class="col-md-10 col-xs-12 offset-1 bg-light border border-white">
                        <button type="submit" class="btn btn-block btn-info" style="margin-top: 10px;">Registrar Tickets</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- <pre>Estado: {{formRegTikets.atatus }}</pre> -->
    <!-- <pre>{{formRegTikets.value | json }}</pre> -->
    <!-- [formControlName]="tik.codTiket"
  [formControlName]="tik.tpJuego" -->
