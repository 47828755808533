import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  estado: string = '';
  id: number = 0;
  nombre: string ='';

  constructor() {
    this.estado = 'buscar';
  }

  ngOnInit(): void {


  }

  cambiarEstado(nuevoEstado:any){
    this.estado = nuevoEstado.estado;
    this.id = nuevoEstado.cliente;
    this.nombre = nuevoEstado.nombreCliente;
  }

}
