<div class="container-fluid">
    <div id="titulo" class="row">
        <h4>¡Participa!</h4>
    </div>
    <div class="row">
        <div id="contenedor" class="col-md-12 col-xs-12 bg-light border border-white">
            <form autocomplete="off" [formGroup]="formDni" (ngSubmit)="buscarCliente()">
                <div class="form-group">
                    <label>Digite su documento de identidad:</label>
                    <input id="impDni" class="form-control col-md-12 col-xs-12" type="text" name="dni" formControlName="dni" autocomplete="off" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)">
                    <small *ngIf="dniNoValido" class="form-text text-danger">Ingrese minimo 5 digitos</small>
                </div>
                <div id="captcha" class="text-center">
                    <re-captcha (resolved)="resolved($event)" siteKey="6LcrOCYpAAAAAG5tEq5ALRE1LwJ18Ui0UOInSEaE">
                    </re-captcha>
                    <small class="form-text text-danger">{{response}}</small>
                </div>
                <div class="text-center">
                    <button type="submit" [disabled]="disabledButon" class="btn btn-info">Participar</button>
                </div>
            </form>
        </div>
    </div>